.map-container {
  width: 100vw;
  height: 100vh;
}

.menu-btn-div {
  text-align: left;
  padding: 0;
  margin: 0;
}

.menu-back-btn-div {
  text-align: left;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

.sidebar-content {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
  height: calc(100vh - 16px);
  overflow-y: auto;
}

.sidebar-content::-webkit-scrollbar {
  -webkit-appearance: none;
}
.sidebar-content::-webkit-scrollbar:horizontal {
  height: 11px;
}
.sidebar-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, .5);
}
.sidebar-content::-webkit-scrollbar-track { 
  background-color: #bbb; 
  border-radius: 0px; 
} 

.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 12px;
  font-family: monospace;
  border-radius: 4px;
}

.bottom-sidebar {
  color: #fff;
  font-family: monospace;
  background-color: rgb(35 55 75 / 90%);
  padding: 2px 2px;
  z-index: 1;
  position: absolute;
  bottom: 12px;
  right: 0;
  margin: 12px;
  border-radius: 4px;
}

.sidebar-distance {
  font-size: 1.6em;
}

.sidebar-elevation {
  font-size: 1.2em;
  margin: 10px 0px;
}

.elevation-container {
  border: 1px solid white;
  padding: 0px;
}

.sidebar-btn-container {
  justify-content: center;
  margin: 10px;
}

.options {
  margin: 10px;
}

.marker {
  background-color: white;
  border: 2px solid black;
  padding: 5px;
  display: inline-block;
  border-radius: 50%;
}

.start-marker {
  background-color: rgb(11, 243, 23);
  border: 2px solid black;
  padding: 5px;
  display: inline-block;
  border-radius: 50%;
}

.end-marker {
  background-color: rgb(248, 42, 6);
  border: 2px solid black;
  padding: 5px;
  display: inline-block;
  border-radius: 50%;
}

.add-new-marker {
  background-color: #bbbc;
  border: 2px solid #555d; /* Slightly darker & less opaque than bkg*/
  padding: 5px;
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
}

.marker-line-h {
  width: 8px;
  margin-top: -1px;
  margin-left: -4px;
  border-bottom: 2px solid #333;
  position: absolute;
}
.marker-line-v {
  height: 8px;
  margin-top: -4px;
  margin-left: -1px;
  border-right: 2px solid #333;
  position: absolute;
}

.marker-popup-btn {
  margin: 5px;
  background-color: rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  border-width: 0;
  color: #303030;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 12px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.strava-connected-div {
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top: -15px;
}

.strava-connected-p {
  margin-right: 8px;
  color: white;
}

.sidebar-options-div {
  color: white;
  margin: 0 8px;
}

.post-to-strava-drawer-div {
  margin: 0 8px;
}

.import-export-drawer-div {
  text-align: center;
}

.drawer-button {
  display: block;
  width: 100%;
}

.drawer-help-buttons-div {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: end;
  text-align: center;
}

.footer-link {
  align-self: flex-end;
  color: white;
  font-size: 1em;
}

.top-footer-link-div {
  margin-top: 16px;
}

.footer-link-div {
  margin-bottom: 4px;
}

.distance-popup {
  z-index: 1;
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 2px 8px;
  font-family: monospace;
  border-radius: 4px;
}

/* Mobile styling */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .desktop-controls {
    display: none;
  }

  .mobile-controls {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 2;
  }

  .mobile-action-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    background: rgb(35 55 75 / 90%);
    padding: 5px;
    border-radius: 4px;
  }

  .mobile-action-buttons .MuiIconButton-root {
    margin: 2px 0;
  }

  .mobile-stats {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(35 55 75 / 90%);
    color: white;
    padding: 8px 16px;
    font-family: monospace;
    z-index: 2;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-stats-main {
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-size: 1.2em;
    align-items: center;
    z-index: 2;
  }

  .mobile-stats-logo {
    padding: 0 12px;
  }

  .mobile-stats-logo img {
    height: 20px;
    opacity: 0.8;
  }

  .mobile-stats-distance,
  .mobile-stats-elevation {
    flex: 1;
    text-align: center;
  }

  .sidebar {
    background-color: #0000; 
  }

  .sidebar-content {
    height: auto;
    margin-top: 0;
  }

  .menu-btn-div {
    background: rgb(35 55 75 / 90%);
    padding: 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom-sidebar {
    display: none;
  }

  .mobile-controls-popup {
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    background: rgb(35 55 75 / 95%);
    color: white;
    padding: 24px 16px;
    transition: bottom 0.3s ease-in-out;
    z-index: 2;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    max-height: 80vh;
    overflow-y: auto;
    max-width: 400px;
    margin: 0 auto;
  }

  .mobile-controls-popup.open {
    bottom: 0;
  }

  .mobile-controls-popup .MuiFormControlLabel-root {
    margin: 0px;
    justify-content: space-between;
  }

  .mobile-controls-popup .MuiSwitch-root,
  .mobile-controls-popup .MuiRadio-root {
    padding: 12px;
  }

  .mobile-controls-popup .MuiFormControl-root {
    min-width: 100%;
    max-width: 100%;
    margin: 16px 0;
  }

  .mobile-controls-popup .MuiFormGroup-root {
    min-width: 100%;
    max-width: 100%;
    align-items: center;
  }

  .mobile-controls-popup .MuiRadioGroup-root {
    min-width: 100%;
    max-width: 100%;
    justify-content: center;
  }

  .mobile-controls-popup-header {
    position: absolute;
    top: 8px;
    left: 8px;
  }

  .mobile-controls-popup .MuiFormLabel-root {
    text-align: center;
    width: 100%;
  }
}

/* Hide mobile elements on desktop */
@media only screen and (min-device-width: 481px) {
  .mobile-controls {
    display: none;
  }
  
  .mobile-stats {
    display: none;
  }
}