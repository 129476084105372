/* Not a fan of this, but the mapbox search box messes up this field for all inputs b/c they use !important*/
#strava-title, #strava-description, #strava-date, #strava-time, #strava-hours, #strava-minutes, #strava-seconds, #strava-distance, #strava-pace {
  box-sizing: content-box !important;
}

.strava-grid-container {
  display: grid;
  grid-template-columns: repeat(24, [col] 1fr);
  max-width: 500px;
}

.strava-cell {
  padding: 2px;
  flex-direction: column;
  display: flex;
}

.grid-item-title {
  grid-column: col / span 24;
}

.grid-item-desc {
  grid-column: col / span 24;
}

.grid-date {
  grid-column: col / span 12;
}

.grid-time {
  grid-column: col 13 / span 12;
}

.grid-hours {
  grid-column: col / span 8;
}

.grid-mins {
  grid-column: col 9 / span 8;
}

.grid-secs {
  grid-column: col 17 / span 8;
}

.grid-activity {
  grid-column: col / span 10;
}

.grid-distance {
  grid-column: col 11 / span 7;
}

.grid-pace {
  grid-column: col 18 / span 7;
}

.grid-use-gear {
  grid-column: col / span 12;
}

.grid-gear {
  grid-column: col 13 / span 12;
}

.map-question-link {
  grid-column: col / span 24;
  width: fit-content;
}